@tailwind base;
@tailwind components;
@tailwind utilities;

/* Optional: Global Reset and Custom Overrides */
body {
  @apply m-0 font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  @apply font-mono;
}
